<template>
  <div id="app">
   

  <router-view v-if="isRouterAlive" v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.name"  v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component" :key="$route.name"  v-if="!$route.meta.keepAlive"/>
  </router-view>


    <!-- <router-view v-if="isRouterAlive"/> -->
    <div style="height: 3.125rem"></div>
  </div>
</template>

<script setup>
import { useUserStore } from './store/user';
import { storeToRefs } from "pinia";
import Mqtt from './api/wsconnect';
import { onBeforeMount, onBeforeUnmount, nextTick, provide, ref } from 'vue';

const store=useUserStore()
const { keepAlive } = storeToRefs(store);
onBeforeMount(() => {
  try {
    Mqtt.instance.initConnect();
    Mqtt.instance.setNotifyCB((msg)=>{
      console.log("notify msg", msg)
    })
  } catch (e) {
    console.log(e);
  }
});
onBeforeUnmount(() => {
  try {
    Mqtt.instance.client.end();
  } catch (e) {
    console.log(e);
  }
});

const isRouterAlive = ref(true);
const reload = () => {
  console.log('我在刷新页面')
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
};
provide('reload', reload);
</script>

<style lang="scss">
html,
body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.van-nav-bar .van-icon {
  color: #323233 !important;
}
.van-nav-bar__text{
  color: #323233 !important;
}
</style>
