//生产地址
export const BaseUrl = 'https://www.baidu.com'
// export const MqttUrl = 'ws://qinfang.test.szzcloud.club/mqtt'
export const MqttUrl = 'wss://tianyuan.51xtc.top/mqtt'
// export const BaseUrl =
//   window.location.protocol + "//" +
//   (window.location.hostname || window.location.host) + ":" +
//   window.location.port;

//测试地址
// export const BaseUrl = 'https://www.baidu.com'

//Session Key
// export const SessionKey = {
//     Token: "token"
// };

//方法获取 例子：
// export const getUrl = function (par){
//   return BaseUrl + `/#/index?par=${par}`
// }

//常量 例子：
// export const OnlineStatus = {
//   Online: "ONLINE",
//   Offline: "OFFLINE",
//   Abnormal: "ABNORMAL",
// }