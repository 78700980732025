import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
// import {countInitUidTimeDiffTodayTime} from '../../src/utils/index.js'

const routes = [{
	path: '/login',
	name: 'login',
	component: () => import('@/views/Login'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
},
// {
// 	path: '/',
// 	component: () => import('@/views/HomeTabbar'),
// 	redirect: '/home_trends',
// 	meta: {
// 		keepAlive: true,
// 		needLogin: true
// 	},
// },
// {
// 	path: '/home_trends',
// 	name: 'home_trends',
// 	component: () => import('@/views/HomeTrends'),
// 	meta: {
// 		keepAlive: true,
// 		needLogin: true
// 	},
// },
// {
// 	path: '/ai_assistant',
// 	name: 'ai_assistant',
// 	component: () => import('@/views/AIAssistant'),
// 	meta: {
// 		keepAlive: true,
// 		needLogin: true
// 	}
// },
// {
// 	path: '/home_data',
// 	name: 'home_data',
// 	component: () => import('@/views/HomeData'),
// 	meta: {
// 		keepAlive: true,
// 		needLogin: true
// 	}
// },
// {
// 	path: '/home_my',
// 	name: 'home_my',
// 	component: () => import('@/views/HomeMy'),
// 	meta: {
// 		keepAlive: true,
// 		needLogin: true
// 	}
// },
{
	path: '/',
	component: () => import('@/views/HomeTabbar'),
	redirect: '/home_trends',
	meta: {
		keepAlive: true,
		needLogin: true
	},
	children: [{
		path: '/home_trends',
		name: 'home_trends',
		
		component: () => import('@/views/HomeTrends'),
		meta: {
			keepAlive: true,
			needLogin: true,
			tabKey:'',
		},
	},
	{
		path: '/ai_assistant',
		name: 'ai_assistant',
		component: () => import('@/views/AIAssistant'),
		meta: {
			keepAlive: true,
			needLogin: true
		}
	},
	{
		path: '/home_data',
		name: 'home_data',
		component: () => import('@/views/HomeData'),
		meta: {
			keepAlive: true,
			needLogin: true
		}
	},
	{
		path: '/home_my',
		name: 'home_my',
		component: () => import('@/views/HomeMy'),
		meta: {
			keepAlive: true,
			needLogin: true
		}
	},

	]
},
{
	path: '/detail',
	name: 'detail',
	component: () => import('../views/Detail.vue'),
	meta: {
		keepAlive: false,
		needLogin: true
	}
}, {
	path: '/person_data',
	name: 'person_data',
	component: () => import('../views/PersonData.vue'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
},
{
	path: '/setting',
	name: 'setting',
	component: () => import('../views/Setting.vue'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
}, {
	path: '/error',
	name: 'error',
	component: () => import('../views/Error.vue'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
},

{
	path: '/testRecorder',
	name: 'testRecorder',
	component: () => import('../views/components/testRecorder.vue')
},

{
	path: '/updatePassword',
	name: 'updatePassword',
	component: () => import('../views/UpdatePassword.vue'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
},
{
	path: '/feedBack',
	name: 'feedBack',
	component: () => import('../views/setting/Feedback.vue'),
	meta: {
		keepAlive: true,
		needLogin: true
	}
},


]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router