import { defineStore } from 'pinia'

export const useUserStore = defineStore('user',{
    state:()=>{
        return {
            userId: null,
            token: null,
            detailBackFalg:null,
            keepAlive: [], // 保存需要缓存的组件
        }
    },
    //开启持久化
    persist: {
        enabled: true,
    },
    actions: {
        login(userInfo) {
            this.userId = userInfo.user_id
            this.token = userInfo.token
        },
        logout() {
            this.userId = null
            this.token = null

        },
        detailBackFalgFun(val){
            this.detailBackFalg=val
        },
        setKeepAlive(value) {
            this.keepAlive = value;
          },
    }
})

