const mqtt = require('mqtt')
const { MqttUrl } = require('./constants')

const topicPrefix = 'teacher_manage_web'
const topics = {
  api_result: 'api_result',
  api_request: 'api_request',
  notify: 'notify'
}

export default class Mqtt {
  uid; //当前的唯一标识
  clientId;
  callBack; //回调内容包括 回调方法、请求标识UUID、分组管理标识(页面标识)、超时时间节点
  notifyCB;
  subscribeTopics; //订阅的Topic
  client;
  timer;
  constructor() {
    this.uid = this.get_req_uid()
    this.clientId = 'teacher_manage_web' + new Date().getTime() + Math.ceil(Math.random() * 99999)
    this.callBack = []
    this.notifyCB = null
    this.subscribeTopics = []
    this.timer = setInterval(()=>{
      this.removeTimeoutCallback()
    }, 1000)
  }
  static get instance(){
    if(!Mqtt._instance){
      Object.defineProperty(Mqtt,'_instance',{
        value : new Mqtt()
      })
    }
    return Mqtt._instance;
  }
  initConnect(){
    let options = {
      clientId: this.clientId,
      protocolVersion: 4,
      // username: 'username',
      // password: 'password',
      reconnectPeriod: 2 * 1000,
      connectTimeout: 20 * 1000,
      keepalive: 30,
      reschedulePings: true,
      resubscribe: false,
      clean: true, //清理会话
    }

    this.client = mqtt.connect(MqttUrl, options);
    let api_result_topic = topics.api_result
    let notify_topic = topics.notify
    let uid = this.uid
    this.subscribeTopics = [
      `/${topicPrefix}/${api_result_topic}/${uid}`,
      `/${topicPrefix}/${notify_topic}/${uid}`
    ]
    this.client.on('connect', connack => {
      console.log('connect', connack);
      this.subscribeTopics.length && this.client.subscribe(this.subscribeTopics, {qos: 0}, function (err, granted) {
        console.log(err, granted)
      })
    })
    this.client.on('reconnect', connack => {
      console.log('server-local-connect', connack);
      this.subscribeTopics.length && this.client.subscribe(this.subscribeTopics, {qos: 0}, function (err, granted) {
        console.log(err, granted)
      })
    })

    this.client.on('message', (topic, message, packet) => {
      if(!message){
        console.log('mqtt-message empty');
        return;
      }

      let msg = message.toString()
      console.debug('mqtt-message', msg);
      if (/^{.+}$/.test(msg)) {
        msg = JSON.parse(msg);

        if(msg.event_type === "api_result"){//走接口
          // fun、过期时间、页面实例id、api、request_id
          let callBackArr = this.callBack
          for(let i = 0; i < callBackArr.length; i++){

            let cb_obj = callBackArr[i]
            if(cb_obj.request_id === msg.request_id){
              callBackArr.splice(i, 1)
              try {
                typeof cb_obj.fun === 'function' && cb_obj.fun(msg.data)
              }catch (e){
                console.log('回调方法执行报错', e)
              }
              return;
            }
          }

          console.log('request_id 不存在,舍弃', msg)
        }else if(msg.event_type === "notify"){//走通知
          if(this.notifyCB && typeof this.notifyCB === 'function'){
            try {
              this.notifyCB(msg)
            }catch (e){
              console.log('通知回调方法执行报错', e)
            }
          }

          //TODO 判断是否登录并且登陆的userid 和 msg.user_id一致
          //TODO 判断是否是 AI分析结果通知 msg.notify_type = 1 AI分析结果通知
          //TODO 回调通知到全局的页面
          console.log('mqtt 无匹配操作');
        }
      }else {
        // //无匹配
        //  console.log('mqtt 无匹配操作');
        // return;
      }
    })

    this.client.on('error', function (error) {
      console.log('server-local-error', error);
    });
    return this;
  }
  generateUUID() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    });
  }
  // 获取uid
  get_req_uid () {
    if (sessionStorage.getItem('request_remote_uid')) {
      return sessionStorage.getItem('request_remote_uid')
    } else {
      let uid = this.generateUUID()
      sessionStorage.setItem('request_remote_uid', uid)
      return uid
    }
  }
  setNotifyCB(cb) {
    this.notifyCB = cb
  }
  apiRequest (cb) {
    let new_cb = {
      api: cb.api,
      fun: cb.fun,
      param: cb.param,
      request_id: this.generateUUID(),
      group_id: cb.group_id,
      timeout: cb.timeout //超时时间，毫秒
    }

    let that = this
    return new Promise(function (resolve, reject) {
      if(!new_cb.api || !new_cb.request_id || !new_cb.fun){
        return reject(new Error("api、fun 不能为空"))
      }
      if(new_cb.timeout){
        new_cb.timeout = Date.now() + new_cb.timeout
      }else {
        //添加默认超时时间
        new_cb.timeout = Date.now() + 10 * 1000
      }

      let message = {
        api: new_cb.api,
        uid: that.uid || that.get_req_uid(),
        request_id: new_cb.request_id,
        param: new_cb.param
      };

      that.callBack.push(new_cb)
      let api_request_topic = topics.api_request
      that.client.publish(`/${topicPrefix}/${api_request_topic}`, JSON.stringify(message), function (err) {
        console.log("apiRequest", err)
        if(err){
          let callBackArr = that.callBack
          for(let i = 0; i < callBackArr.length; i++){
            let cb_obj = callBackArr[i]
            if(cb_obj.request_id === new_cb.request_id){
              callBackArr.splice(i, 1)
              try {
                typeof cb_obj.fun === 'function' && cb_obj.fun({
                  code: 9999001,
                  msg: '网络错误'
                })
              }catch (e){
                console.log('回调方法执行报错', e)
              }
              return;
            }
          }
        }
      })
      resolve()
    })
  }
  removeApiCallback (request_id) {
    let callBackArr = this.callBack
    for(let i = 0; i < callBackArr.length; i++){
      if(callBackArr[i].request_id === request_id){
        callBackArr.splice(i, 1)
        return;
      }
    }
  }
  removeGroupCallback (group_id) {
    let callBackArr = this.callBack
    for(let i = 0; i < callBackArr.length; i++){
      if(callBackArr[i].group_id === group_id){
        callBackArr.splice(i, 1)
        return;
      }
    }
  }
  removeTimeoutCallback () {
    let callBackArr = this.callBack
    let curTime = Date.now()
    for(let i = 0; i < callBackArr.length; i++){
      if(curTime >= callBackArr[i].timeout){
        let cb_obj = callBackArr[i];
        callBackArr.splice(i, 1)
        i--
        try {
          typeof cb_obj.fun === 'function' && cb_obj.fun({
            code: 9999001,
            msg: '请求超时'
          })
        }catch (e){
          console.log('回调方法执行报错', e)
        }
      }
    }
  }
  subscribe (topic, options) {
    console.log('sub topic', topic)
    let that = this
    return new Promise(function (resolve, reject) {
      let client = that.client

      let opt = options || {
        qos: 0
      }
      client.subscribe(topic, opt, function (err, granted) {
        if (err) {
          console.log('subscribe - topic', topic, err)
          return reject(err)
        }
        if (!that.subscribeTopics.includes(topic)) that.subscribeTopics.push(topic)
        console.log('subscribe - topic - granted', topic, granted)
        resolve(granted)
      })
    })
  }
  unsubscribe (topic) {
    console.log('unsubscribe - topic', topic)
    let that = this
    return new Promise(function (resolve, reject) {
      let client = that.client
      client.unsubscribe(topic, function (err) {
        if (err) return reject(err)
        let index = that.subscribeTopics.indexOf(topic)
        console.log("splice callBackArr")
        if (index !== -1) that.subscribeTopics.splice(index, 1)
        resolve()
      })
    })
  }
  async initMQTT (cb) {
    try {
      let client = this.client
      if (client && client.connected) {
        typeof cb === 'function' && cb(client)
        return client
      } else {
        await Promise.reject('NetWork err!')
      }
    } catch (e) {
      throw e
    }
  }
  clearSelf () {
    let that = this
    return new Promise(function (resolve, reject) {
      let client = that.client
      this.callBack = []
      if (Array.isArray(that.subscribeTopics) && that.subscribeTopics.length) {
        client.unsubscribe(that.subscribeTopics, function (err) {
          if (err) return reject(err)
          resolve()
        })
      } else {
        resolve()
      }
    })
  }
}