import {
	createApp
} from 'vue'
import router from './router'
import store from './store'
import { useUserStore } from './store/user';
import Mqtt from './api/wsconnect'
import dayjs from "dayjs"
// import * as utilsapi from '../src/utils/index'
// import * as api from '../src/api/api.js'

import {
	Button,
	Loading,
	Toast,
	Dialog,
	Tabbar,
	TabbarItem,
	PullRefresh,
	List,
	Icon,
	Cell,
	Tab,
	Tabs,
	Image as VanImage,
	DatePicker,
	Popup,
	Search,
	Sticky,
	Picker,
	Field,
	CellGroup,
	PickerGroup,
	Tag,
	Calendar,
	Divider,
	Step,
	Steps,
	Badge,
	Overlay,
	BackTop,
	Form,
	NavBar,
	Lazyload,
	showToast,
	showDialog,
	Empty
} from 'vant'

import App from './App.vue'

import 'vant/lib/index.css'
import "videojs-contrib-hls";

const app = createApp(App)

app.use(store)
	.use(router)
	.use(Button)
	.use(Loading)
	.use(Toast)
	.use(Dialog)
	.use(Tabbar)
	.use(TabbarItem)
	.use(PullRefresh)
	.use(List)
	.use(Icon)
	.use(Cell)
	.use(Tab)
	.use(Tabs)
	.use(VanImage)
	.use(DatePicker)
	.use(Popup)
	.use(Search)
	.use(Sticky)
	.use(Picker)
	.use(Field)
	.use(CellGroup)
	.use(PickerGroup)
	.use(Tag)
	.use(Calendar)
	.use(Divider)
	.use(Step)
	.use(Steps)
	.use(Badge)
	.use(Overlay)
	.use(BackTop)
	.use(Form)
	.use(NavBar)
	.use(Lazyload)
	.use(Empty)


	.mount('#app')



// app.component(BackTop)

router.beforeEach(function (to, from, next) {
	const store = useUserStore();
	console.log('to.meta', to, to.meta, to.name)
	console.log(router.options.routes)

	// 从详情返回主页时 取出tabkey判断
	let tabKey = sessionStorage.getItem('tabKey');
	console.log('tabKey', tabKey);	// let yy = api.getToday()
	// if (to.name === 'home_trends' && from.name === 'setting') {
		// console.log('to.name',to.name)
		// console.log('from.name',from.name)
		
	if (to.name === 'home_trends'&&from.name==='detail') {
		// 把tabkey传给home_trends
		to.meta.tabKey = tabKey
		console.log('我是详情页面回到列表页面啦')
		console.log('to.name',to.name)
		console.log('from.name',from.name)
		console.log('store.detailBackFalgFun',store.detailBackFalg)
		store.detailBackFalgFun(true)
	}else{
		store.detailBackFalgFun(false)
	}
	// userUidObj
	// [
	// 	{
	// 		"userid": "2",
	// 		"uid": "cd10b734-8f35-3391-afc9-28dfaea7df52",
	// 		"initTime": "2023-05-12 11:10:20"
	// 	}
	// ]
	// console.log(yy)
	// 时间差
	if (to.name !== 'login') {
		let initTime = ''
		let nowUser = sessionStorage.getItem('nowUser')

		// let nowTime=''
		// let remainder=''
		let userUidObj = JSON.parse(localStorage.getItem('userUidObj'))
		// let userUidTimeList = localStorage.getItem('userUidTimeList')
		console.log('userUidTimeList', userUidObj)
		if (userUidObj && userUidObj !== null) {
			if (nowUser === userUidObj.userid) {
				initTime = userUidObj.initUidTime
				// 获取当前时间
				let nowTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
				// 计算时间差
				let remainder = dayjs(nowTime).diff(initTime, 'hours');
				console.log('uid时间差为', remainder)
				if (remainder > 12) {
					againBindUID()
				}
			} else {
				againBindUID()

			}

		} else {
			againBindUID()

		}

		// userUidTimeList.forEach((item) => {

		// })

		console.log('初始化uid时间为', initTime)

		// if (remainder > 12) {

		// }
	}



	// return false
	if (to.meta.needLogin) {
		if (to.name === 'login') {
			if (window.localStorage.getItem('access_token_dd')) {
				router.push({
					name: 'home_trends'
				})
			}
			console.log('现在是login')
			next()
		}
		//页面是否登录
		if (window.localStorage.getItem('access_token_dd')) {
			//本地存储中是否有token(uid)数据
			console.log('token', window.localStorage.getItem('access_token_dd'))
			next(); //表示已经登录
		} else {
			console.log('还没登陆 跳转登录')
			//next可以传递一个路由对象作为参数 表示需要跳转到的页面
			console.log(to.name, from.name)
			// if (to.name === 'login') {
			next({
				name: "login"
			});
			// next()
		}
	} else {
		//表示不需要登录
		next(); //继续往后走
	}
});



const againBindUID = () => {
	// 从新绑定uid
	let param = {
		"app_key": "3F6AE6D9E-244D-46B1-A8CC-3D5A90C3823A",
		"app_pass": "D410FAC1-7b0A-4522-AFEA-B3650AF14CA1",
		"access_token": localStorage.getItem('access_token_dd'),
		uid: sessionStorage.getItem('request_remote_uid')
	}
	Mqtt.instance.apiRequest({
		api: 'api/system.self.bind_uid',
		param: param,
		group_id: '', //页面唯一标识 可使用随机数
		timeout: 7000, //超时时间，毫秒
		fun: (res) => {
			console.log('uid绑定结果', res)
			if (res.code != 10000) {
				// console.log('')
				// showToast('uid绑定异常')
				// showDialog({
				// 	title: '注意',
				// 	message: 'uid绑定异常'
				// }).then(() => {
				// 	// next()
				// 	router.push({
				// 		name: 'login'
				// 	})
				// })
				// next()
				// cd10b734-8f35-3391-afc9-28dfaea7df52
				
			} else {
				// 绑定成功 更新当前时间
				let obj = {
					uid: sessionStorage.getItem('request_remote_uid'),
					userid: sessionStorage.getItem('nowUser'),
					initUidTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
				};

				// window.localStorage.setItem('initUidTime', initUidTime);

				window.localStorage.setItem('userUidObj', JSON.stringify(obj));
				// localStorage.setItem('userUidTimeList', JSON.stringify(userUidTimeList))
			}
		}
	});
}


